import * as React from 'react'
import { useStaticQuery, graphql } from "gatsby"

import Layout from '../../../layouts'
import Grid from '../../../components/elements/grid'
import LargeHero from '../../../components/elements/largeHero'
import { SizedText } from '../../../components/elements/typography'
import SEO from '../../../components/elements/seo'

const ProductTable = ({data}) => {
  
  const rows = data.map(i => (
    <div className='divTableRow'>
      <div className="divTableCell cl1"><b>{i.product}</b></div>
      <div className="divTableCell">{i.qty}</div>
      <div className="divTableCell">${i.price}</div>
      <div className="divTableCell">${i.priceWithTax}</div>
      <div className="divTableCell">${i.shippingCost}</div>
    </div>
  ))
  
  return (
    <div css={{
      display: `table`,
      width: `100%`,
      '.divTableRow': {
        display: `table-row`,
      },
      '.divTableHeading': {
        backgroundColor: `#EEE`,
        display: `table-header-group`,
      },
      '.divTableCell, .divTableHead': {
        border: `1px solid #999999`,
        display: `table-cell`,
        padding: `3px 10px`,
      },
      '.divTableHeading': {
        backgroundColor: `#EEE`,
        display: `table-header-group`,
        fontWeight: `bold`,
      },
      '.divTableFoot': {
        backgroundColor: `#EEE`,
        display: `table-footer-group`,
        fontWeight: `bold`,
      },
      '.divTableBody': {
        display: `table-row-group`,
      },
      '.cl1': {width: `33%`}
    }}>
      <div className='divTableHeading'>
        <div className="divTableRow">
          <div className="divTableHead cl1">Product</div>
          <div className="divTableHead">Qty.</div>
          <div className="divTableHead">Price</div>
          <div className="divTableHead">Price With Tax</div>
          <div className="divTableHead">Shipping</div>
        </div>
      </div>
      <div className="divTableBody">
        {rows}
      </div>
    </div>
  )
}

const CheckPrintingPage = ({}) => {
  const data = useStaticQuery(graphql`
    query CheckPrintingQuery {
      productsPersonalchecksYaml {
        productType {
          category
          products {
            price
            priceWithTax
            product
            qty
            shippingCost
          }
        }
      }
    }
  `)

  const productsList = data.productsPersonalchecksYaml.productType.map(i => (
    <div>
      <h2>{i.category}</h2>
      <ProductTable data={i.products} />
    </div>
  ))

  return (
    <Layout isSubMenuOpen={true} openAccountLink={true}>

      <SEO
        title="TCB Personal Check Printing"
        description="Stop waiting for your checks, and start ordering them from TCB. We print all of our checks in-house, which means no more long waits for delivery – you’ll get your checks in days, not weeks."
        location="/personal/services/check-printing"
        ogImg='/images/social-graph/the-claxton-bank.jpg'
      />

<LargeHero.Container
        padding="3rem 0 4rem 0"
        background={{
          //backgroundColor: `#b2a08a`,
          backgroundImage: `url(/images/background-deltas.jpg)`, 
          backgroundRepeat: `no-repeat`, 
          backgroundAttachment: `scroll`, 
          backgroundPosition: `top left`, 
          backgroundSize: `cover`,
        }}
        breadcrumbLinks={[
          { path: "/", text: "Home" },
          { path: "/personal/", text: "Personal Banking" },
          { path: "/personal/services/", text: "Other Services" },
          { path: "/personal/services/check-printing", text: "Check Printing" }
        ]}
        styles={{
          paddingBottom: `5rem`,
          overflow: `hidden`,
          '.breadcrumbs, .breadcrumbs [aria-current="page"]': {color: `#fff`},
          '.heroContent': { paddingBottom: `3rem`},
          '@media (min-width: 680px)': {
            '.heroContent': { width: `calc(72% - 1rem)`, marginBottom: `0`},
            '.heroImage': { width: `calc(40% - 1rem) !important`,},
          }
        }}
      >
        <LargeHero.Content styles={{paddingTop: `1rem`, color: `#fff`}}>
          <LargeHero.Eyebrow text="Personal Services" styles={{borderColor: `#fff !important`}}/>
          <h1 css={{fontFamily: `Proxima-nova`, textTransform: `uppercase`,  'span': {display: `block`, lineHeight: `1`, padding: `0.5rem 0`}}}>
            <SizedText as="span" smallSize="2.281" largeSize="4.624">Check Printing</SizedText>
          </h1>
        </LargeHero.Content>
        <LargeHero.Image >

        </LargeHero.Image>
      </LargeHero.Container>

      <Grid.FullWidthSection>
        <Grid.Block start={['4']} span={["8"]} css={{padding: `6rem 0`}}>
          {productsList}
        </Grid.Block>
      </Grid.FullWidthSection>
    </Layout>
  )

}

export default CheckPrintingPage

